/* jshint esversion: 6 */

import React from 'react';
import axios from 'axios';
import * as QueryString from 'query-string';
import { connect } from 'react-redux';
import OrderDetailsMeal from './OrderDetailsMeal';
import OrderDetailsModal from './OrderDetailsModal';
import * as Constants from './Constants';

const OrderDetails = (props) => {
    const params = QueryString.parse(props.location.search);
    const id = Number(params.id);
    const user = params.user;
    const table = params.table;
    const [order, setOrder] = React.useState({});
    const [itemsToTransfer, setItemsToTransfer] = React.useState([]);
    const [itemsToDiscount, setItemsToDiscount] = React.useState([]);
    const [groupDiscount, setGroupDiscount] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [discounts, setDiscounts] = React.useState([]);
    const [fixedTotal, setFixedTotal] = React.useState(0);

    const planplus = axios.create({
        baseURL: Constants.BASE_URL,
        auth: {
            username: props.user.username,
            password: props.user.password
        }
    });

    React.useEffect(() => {
        /* jshint ignore:start */
        const getOrder = async () => {

            const data = await planplus.get(`/hr/orders/api/${id}`);
            setOrder(data.data)
        };
        /* jshint ignore:end */
        getOrder();


    }, []);

    React.useEffect(() => {
        order.orderitem_set && order.orderitem_set.forEach(({ item, quantity, extras, discount = 0 }, index) => {

            const itemToDiscount = {
                index,
                item,
                quantity,
                extras,
                discount
            };

            setItemsToDiscount((itemsToDiscount) => { return [...itemsToDiscount, itemToDiscount]; });
            setTotal(order.total);
        });
    }, [order]);

    const handleSetFixedTotal = (mealTotalPrice) => {
        setFixedTotal((fixedTotal) => {
            return fixedTotal + mealTotalPrice;
        });
    };

    const setDiscountedTotal = (discountedAmount, index) => {

        setDiscounts((discounts) => {
            const filteredDiscounts = discounts.filter((discount) => {
                return discount.index !== index;
            });

            const updatedDiscounts = [...filteredDiscounts, {
                index,
                amount: Number(discountedAmount)
            }];

            const mappedDiscounts = updatedDiscounts.map((discount) => { return discount.amount; });
            const discountToReduce = mappedDiscounts.reduce((a, b) => a + b, 0);

            setTotal(() => {
                if (order.total > fixedTotal) {
                    return order.total;
                } else {
                    return fixedTotal - discountToReduce;
                }                
            });

            return updatedDiscounts;
        });
    };

    const startSetDiscount = (discount, index) => {
        const selectedItem = itemsToDiscount.findIndex((item) => {
            return item.index === index;
        });
        if (selectedItem !== -1) {
            const newItemsToDiscount = [...itemsToDiscount];
            newItemsToDiscount[selectedItem].discount = Number(discount);
            setItemsToDiscount(newItemsToDiscount);
        }
    };

    const handleSetItemsToTransfer = (item, quantity, extras, index) => {

        const mealToAdd = {
            index,
            item,
            quantity,
            extras
        };

        const duplicateItem = itemsToTransfer.find((item) => {
            return item.index === mealToAdd.index;
        });

        if (!duplicateItem) {
            const newItems = [...itemsToTransfer, mealToAdd];
            setItemsToTransfer(newItems);
        } else {
            const newItems = itemsToTransfer.filter((item) => { return item.index !== mealToAdd.index; });
            setItemsToTransfer(newItems);
        }
    };

    /* jshint ignore:start */
    const splitOrder = async () => {

        const dataToSend = {
            "label": order.label,
            "items": itemsToTransfer
        };

        const data = await planplus.put('/hr/orders/split/', dataToSend);
        props.history.push(`/table?table=${table}&user=${user}`);
    };
    /* jshint ignore:end */

    const handleSetGroupDiscount = (sliderValue) => {
        setGroupDiscount(Number(sliderValue));
    };

    /* jshint ignore:start */
    const setDiscount = async () => {

        console.log(itemsToDiscount);

        const dataToSend = {
            "label": order.label,
            "items": itemsToDiscount
        };

        console.log(dataToSend);

        const data = await planplus.put('/hr/orders/discount/', dataToSend);
        props.history.push(`/table?table=${table}&user=${user}`);
    };
    /* jshint ignore:end */

    const renderOrderDetails = () => {
        const year = order.issue_date.slice(0, 4);
        const month = order.issue_date.slice(5, 7);
        const day = order.issue_date.slice(8, 10);
        const time = order.issue_date.slice(11, 16);

        return (
            /* jshint ignore:start */
            <div>
                <h3 className="details-subtitle"><span>Narudžba:</span> {order.label}</h3>
                <p>ID: <span>{order.id}</span></p>
                <p>Stol: <span>{order.table}</span></p>
                <p>Datum: <span>{`${day}.${month}.${year}.`}</span></p>
                <p className="margin-bottom">Vrijeme: <span>{time} h</span></p>
                <div className="d2-order-details">
                    <div className="d3">
                        <div className="d4">
                            <div className="d5">
                                {order.orderitem_set && order.orderitem_set.map((meal, index) => {
                                    const selected = itemsToTransfer.findIndex((item) => { return item.index === index });
                                    return <OrderDetailsMeal
                                        groupDiscount={groupDiscount}
                                        meal={meal}
                                        handleSetItemsToTransfer={handleSetItemsToTransfer}
                                        index={index}
                                        selected={selected}
                                        handleSetGroupDiscount={handleSetGroupDiscount}
                                        startSetDiscount={startSetDiscount}
                                        setDiscountedTotal={setDiscountedTotal}
                                        handleSetFixedTotal={handleSetFixedTotal}
                                    />
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                {order.note && <p>Napomena: <span>{order.note}</span></p>}
                <div className="order-controls">
                    <button className="btn-odustani" onClick={() => {
                        props.history.push(`/table?table=${table}&user=${user}`)
                    }}>Povratak</button>
                    {itemsToTransfer.length !== 0 && <a class="btn-prebaci margin-right" href="#open-modal">Prebacite odabrana jela</a>}
                    {itemsToTransfer.length === 0 ? <a className="btn-odustani__inverted" href="#open-modal-discount">Postavi popust</a> : null}
                </div>
                <p className="order-total">Ukupno:&nbsp;<span className="ototal">{itemsToTransfer.length === 0 ? total.toFixed(2) : fixedTotal.toFixed(2)} kn</span></p>
                {itemsToTransfer.length === 0 && <p className="fixed-total">Iznos bez popusta:&nbsp;<span className="ototal ototal-fixed">{fixedTotal.toFixed(2)} kn</span></p>}

                <OrderDetailsModal
                    handleOnClick={itemsToTransfer.length !== 0 ? splitOrder : setDiscount}
                    title={itemsToTransfer.length !== 0 ? 'Prebačaj jela u novu narudžbu' : 'Napravite popust'}
                    text={itemsToTransfer.length !== 0 ? 'Želite li prebacit odabrana jela u novu narudžbu?' : `Želite li napraviti popust sa ${fixedTotal} kn na ${total} kn?`}
                    buttonText={itemsToTransfer.length !== 0 ? 'Prebaci jela' : 'Napravi popust'}
                    id={itemsToTransfer.length !== 0 ? 'open-modal' : 'open-modal-discount'}
                />

            </div>
            /* jshint ignore:end */
        );
    };

    return (
        /* jshint ignore:start */
        <div className="details-wrap">

            {Object.keys(order).length !== 0 ? renderOrderDetails() : (
                <div>
                    <h3 className="subtitle margin-bottom">Učitavanje narudžbe...</h3>
                    <span class="load">
                        <div class="loading-dot"></div>
                        <div class="loading-dot"></div>
                        <div class="loading-dot"></div>
                        <div class="loading-dot"></div>
                    </span>
                </div>
            )}
        </div>
        /* jshint ignore:end */
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(OrderDetails);
