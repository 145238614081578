/* jshint esversion: 6 */

import axios from 'axios';
import * as Constants from '../components/Constants';



export const setExtras = () => {
    /* jshint ignore:start */
    return async (dispatch, getState) => {
        const planplus = axios.create({
            baseURL: Constants.BASE_URL,
            auth: {
                username: getState().user.username,
                password: getState().user.password
            }
        });
        
        
        const { data } = await planplus.get('/hr/items/order/extras/');
        dispatch({
            type: 'SET_EXTRAS',
            extras: data.results
        });
    };
    /* jshint ignore:end */
};