/* jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';
import Order from './Order';

const Menu = (props) => {
    const handleOnClick = (id) => {
        props.history.push(`/meals?id=${id}&table=${props.match.params.table}`);
    };

    const renderCategories = () => {

        return props.menu.map((menuItem) => {
            return (
                /* jshint ignore:start */
                <div className="menu-box" key={menuItem.id} onClick={() => { handleOnClick(menuItem.id) }}>
                    <p>{menuItem.name}</p>
                </div>
                /* jshint ignore:end */
            );
        });
    };

    return (
        /* jshint ignore:start */
        <div className="menu-wrap">
            <Order table={props.match.params.table} />
            <h3 className="subtitle menu-subtitle margin-bottom">Odaberi kategoriju</h3>
            <div className="menu-items-wrap">
                <div class="d2-menu">
                    <div class="d3">
                        <div class="d4">
                            <div class="d5">
                                {renderCategories()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        /* jshint ignore:end */
    );
};

const mapStateToProps = (state) => {
    return {
        menu: state.menu
    };
};

export default connect(mapStateToProps)(Menu);
