/* jshint esversion: 6 */

import React from 'react';

const OrderDetailsModal = ({ handleOnClick, title, text, buttonText, id }) => {

    return (
        /* jshint ignore:start */
        <div id={id} class="modal-window">
        <div>
            <a href="#" title="Close" class="modal-close">Zatvori</a>
            <h1>{title}</h1>
            <div>{text}</div>
            <button className="btn-prebaci margin-top" onClick={handleOnClick}>{buttonText}</button>
            </div>
        </div>
         /* jshint ignore:end */
    );
};

export default OrderDetailsModal;
