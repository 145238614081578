/* jshint esversion: 6 */

export default (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_USER':
            /* jshint ignore:start */
            return {...state, ...action.updates}
            /* jshint ignore:end */
        default:
            return state
    }
}