/* jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';

const OrderDetailsMeal = (props) => {
    const {
        course,
        discount,
        extras,
        id,
        item,
        item_name,
        note,
        price,
        quantity
    } = props.meal;

    const index = props.index;
    const groupDiscount = props.groupDiscount;
    const setDiscountedTotal = props.setDiscountedTotal;
    const handleSetFixedTotal = props.handleSetFixedTotal;
    const startSetDiscount = props.startSetDiscount;

    const [discountBarValue, setDiscountBarValue] = React.useState(discount);
    const [mealTotalPrice, setMealTotalPrice] = React.useState(price);

    React.useEffect(() => {
        setDiscountBarValue(groupDiscount);
    }, [groupDiscount]);

    React.useEffect(() => {
        const tmpMealTotalPrice = createMealTotalPrice();
        startSetDiscount(discountBarValue, index);
        const amountToReduce = tmpMealTotalPrice * (discountBarValue / 100);
        const reducedPrice = (tmpMealTotalPrice - amountToReduce);
        const difference = tmpMealTotalPrice - reducedPrice;
        const addedQuantity = difference * quantity;
        setDiscountedTotal(addedQuantity, index, discountBarValue);
    }, [discountBarValue]);

    React.useEffect(() => {
        const tmpMealTotalPrice = createMealTotalPrice();
        setMealTotalPrice(tmpMealTotalPrice);
        handleSetFixedTotal(tmpMealTotalPrice * quantity);
        
        if (discount) {
            setDiscountBarValue(discount);
        }
    }, []);

    const createMealTotalPrice = () => {
        let tmpMealTotalPrice = price;
        extras.forEach((extra) => {
            const extraFromState = props.extras.find((extraState) => { return extraState.id === extra; });
            tmpMealTotalPrice = tmpMealTotalPrice + extraFromState.price;
        });

        return tmpMealTotalPrice;
    };

    const getCourseName = (course) => {
        if (course === 1) { return 'Predjelo'; }
        if (course === 2) { return 'Glavno jelo'; }
        if (course === 3) { return 'Desert'; }
    };

    return (
        /* jshint ignore:start */
        <div key={Math.random() * id} className="details-meal">
            <div className="order-meal-content">
                <p className="meal-title">{Math.floor(quantity)} x {item_name} <span className="meal-type">- {getCourseName(course)}</span></p>
                <p className="details-price">{price} <span>kn</span></p>
                {props.meal.extras.length !== 0 && <p><span className="tmp-extras">{props.meal.extras.map((extra) => {
                    const extraFromState = props.extras.find((extraState) => { return extraState.id === extra });
                    return <p>
                        <span>
                            {`+ ${extraFromState.name} (`}
                        </span>
                        <span className={Number(extraFromState.price) !== 0 ? 'extra-price-span' : null}>
                            {`+${extraFromState.price}`}
                        </span>
                        <span>{' kn)'}</span>
                    </p>

                })}</span></p>
                }
                <p className="text-transform-none">Ukupno: <span className="extra-price-span">{
                    discountBarValue === 0 ? mealTotalPrice : (mealTotalPrice - (mealTotalPrice * (discountBarValue / 100))).toFixed(2)
                }</span> kn</p>
                {note && <p>Napomena:<span> {note}</span></p>}
                <div class="range-slider">
                    <input onChange={(e) => {
                        setDiscountBarValue(e.target.value)
                    }} class="range-slider__range" value={discountBarValue} type="range" min="0" max="100" step="5" />
                    <span class="range-slider__value">- {discountBarValue} %</span>
                    <a onClick={() => {
                        props.handleSetGroupDiscount(discountBarValue)
                    }} className="all-meals-discount-label">Postavi isti popust za sva jela</a>
                </div>

            </div>
            <div className="order-meal-controls">
                <button onClick={() => {
                    props.handleSetItemsToTransfer(item, quantity, extras, index)
                }
                } className={props.selected === -1 ? 'btn-order-meal-check' : 'btn-order-meal-check-selected'}>
                    {props.selected === -1 ? '+' : String.fromCharCode(10004)}
                </button>
            </div>
        </div>
        /* jshint ignore:end */
    );
};

const mapStateToProps = (state) => {
    return {
        extras: state.extras,
    };
};

export default connect(mapStateToProps)(OrderDetailsMeal);
