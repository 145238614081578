/* jshint esversion: 6 */

import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as QueryString from 'query-string';
import * as Constants from './Constants';

const Table = (props) => {
    const history = useHistory();
    const params = QueryString.parse(props.location.search);
    const table = params.table;
    const user = params.user;
    const [sending, setSending] = React.useState(false);
    const [orders, setOrders] = React.useState([]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    const planplus = axios.create({
        baseURL: Constants.BASE_URL,
        auth: {
            username: props.user.username,
            password: props.user.password
        }
    });

    React.useEffect(() => {

        /* jshint ignore:start */
        const getOrders = async () => {
            
            const { data } = await planplus.get(`/hr/orders/open/?table=${table}`);
            setOrders(data.results);
            let result = 0;
            data.results.forEach((dataResult) => {
                result = result + Number(dataResult.total);
            });
            setTotal(result);
        };

        const getPaymentMethods = async () => {
            const { data } = await planplus.get('/hr/payment-methods/api/');
            setPaymentMethods(data.results);
        };
        /* jshint ignore:end */

        getOrders();
        getPaymentMethods();

    }, []);

    const onValueChange = (id) => {
        setSelectedPaymentMethod(id);
    };

    /* jshint ignore:start */
    const handleSendInovice = async () => {
        setSending(true);
        await planplus.get(`/hr/orders/invoice/?table=${table}&payment_method=${selectedPaymentMethod}`);
        history.push(`/home/${user}`);
    };
    /* jshint ignore:end */

    const renderHeader = () => {
        return (
            /* jshint ignore:start */
            <div className="payment-methods-wrap">
                

                {paymentMethods.map((method) => {
                    return (
                        <div>
                            <label className="label-table">
                                <input
                                    type="radio"
                                    value={method.id}
                                    checked={selectedPaymentMethod === method.id}
                                    onChange={() => { onValueChange(method.id) }}
                                />
                                <span className="checkmark-table"></span>
                                {method.name}
                            </label>
                        </div>
                    )
                })}
                
            </div>
            /* jshint ignore:end */
        );
    };

    const handleViewOrder = (id) => {
        props.history.push(`/details?id=${id}&user=${user}&table=${table}`);
    };

    const renderOrders = () => {
        return orders.map((order) => {
            return (
                /* jshint ignore:start */
                <div onClick={() => { handleViewOrder(order.id) }} key={order.table} className="otvoreni-stolovi-box">
                    <h6 className="color-primary">{order.label}</h6>
                    <p>Ukupno: <span className="ototal">{order.total}</span> kn</p>
                </div>
                /* jshint ignore:end */
            );
        });
    };

    return (
        /* jshint ignore:start */
        <div className="table-wrapp">
            <button disabled={sending} className="button-odjava-light margin-bottom margin-right" onClick={() => {
                props.history.push(`/home/${props.user.id}`)
            }}>Povratak</button>
            <button disabled={sending} className="prebaci-stol disabled margin-bottom" onClick={() => {
                props.history.push(`/tablechange/${table}`)
            }}>{`Prebaci stol ${table}`}</button>
            {renderHeader()}
            <h3 className="table-title">{`Stol ${table}`}</h3>
            <p className="order-total">Ukupno:&nbsp;<span className="ototal">{total} kn</span></p>          
            {orders.length !== 0 ? (
                <div class="d2-table">
                    <div class="d3">
                        <div class="d4">
                            <div class="d5">
                                {renderOrders()}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="margin-topx2">
                    <span class="load">
                        <div class="loading-dot"></div>
                        <div class="loading-dot"></div>
                        <div class="loading-dot"></div>
                        <div class="loading-dot"></div>
                    </span>
                </div>
            )}
            <button disabled={sending} className="btn-posalji-table margin-top margin-bottom" onClick={handleSendInovice}>{sending ? 'Slanje...' : `Izdaj račun za stol ${table}`}</button>
        </div>
        /* jshint ignore:end */
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(Table);
