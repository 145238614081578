/* jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteMeal } from '../actions/order';
import { sendOrder, startDeleteOrder } from '../actions/order';
import OrderMeal from './OrderMeal';

const Order = (props) => {
    const history = useHistory();

    const [note, setNote] = React.useState('');
    const [sending, setSending] = React.useState(false);

    const handleDeleteMeal = (indexOfMealToDelete, mealPrice) => {
        props.deleteMeal(indexOfMealToDelete, mealPrice);
    };

    const handleOrderReset = () => {
        props.startDeleteOrder(props.order.table, history, props.order.user);
        history.push(`/home/${props.order.user}`);
    };

    const handleSendOrder = (history, user) => {
        setSending(true);
        props.sendOrder(history, user, note);
    };

    return (
        /* jshint ignore:start */
        <div className="order-wrap">
            <h3 className="order-subtitle">Narudžba</h3>
            <p>ID konobara: <span>{props.order.user}</span></p>
            <p className="stol-label">Stol: <span>{props.order.table}</span></p>
            {props.order.orderitem_set.length !== 0 && <p>Jela: <span>Pritisnite jelo za uređivanje</span></p>}
            {props.order.orderitem_set.length !== 0 && (
                <div className="d2">
                    <div className="d3">
                        <div className="d4">
                            <div className="d5">
                                {props.order.orderitem_set.map((meal, index) => {

                                    const getCourseName = () => {
                                        if (meal.course === 1) { return 'Predjelo' }
                                        if (meal.course === 2) { return 'Glavno jelo' }
                                        if (meal.course === 3) { return 'Desert' }
                                    };

                                    return <OrderMeal index={index} meal={meal} table={props.order.table} handleDeleteMeal={handleDeleteMeal} getCourseName={getCourseName} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <textarea className="textarea margin-top" placeholder="Unesite napomenu (opcionalno)" value={note} onChange={(e) => setNote(e.target.value)}></textarea>
            <div className="order-controls">
                <button disabled={sending} className="btn-odustani" onClick={handleOrderReset}>Odustani</button>
                <button className="btn-posalji" disabled={props.order.total === 0 || sending} onClick={() => { handleSendOrder(history) }}>{props.order.total === 0 ? 'Dodajte jela prije slanja' : 'Pošalji narudžbu'}</button>
            </div>
            <p className="order-total"><span className="order-dots"></span> Ukupno:&nbsp;<span className="ototal">{props.order.total} kn</span></p>
        </div>
        /* jshint ignore:end */
    );
};

const mapStateToProps = (state) => {
    return {
        order: state.order,
        extras: state.extras
    };
};

export default connect(mapStateToProps, { sendOrder, deleteMeal, startDeleteOrder })(Order);
